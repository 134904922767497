import { FC, SVGProps } from 'react'

export const Hourglass: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_18955_130122)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79375 5.80995C4.7001 5.02878 4.00163 3.82087 3.85991 2.50018H12.1398C11.998 3.82087 11.2996 5.02878 10.2059 5.80995L7.99984 7.38573L5.79375 5.80995Z"
        fill="#656565"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.6665 1.50018C2.39036 1.50018 2.1665 1.72404 2.1665 2.00018C2.1665 2.27633 2.39036 2.50018 2.6665 2.50018H2.85518C3.00027 4.14451 3.85585 5.65464 5.21251 6.62368L7.13961 8.00018L5.21251 9.37668C3.85585 10.3457 3.00027 11.8559 2.85518 13.5002H2.6665C2.39036 13.5002 2.1665 13.724 2.1665 14.0002C2.1665 14.2763 2.39036 14.5002 2.6665 14.5002H13.3332C13.6093 14.5002 13.8332 14.2763 13.8332 14.0002C13.8332 13.724 13.6093 13.5002 13.3332 13.5002H13.1445C12.9994 11.8559 12.1438 10.3457 10.7872 9.37668L8.86007 8.00018L10.7872 6.62368C12.1438 5.65464 12.9994 4.14451 13.1445 2.50018H13.3332C13.6093 2.50018 13.8332 2.27633 13.8332 2.00018C13.8332 1.72404 13.6093 1.50018 13.3332 1.50018H2.6665ZM5.79375 5.80995C4.7001 5.02878 4.00163 3.82087 3.85991 2.50018H12.1398C11.998 3.82087 11.2996 5.02878 10.2059 5.80995L7.99984 7.38573L5.79375 5.80995ZM5.79375 10.1904L7.99984 8.61463L10.2059 10.1904C11.2996 10.9716 11.998 12.1795 12.1398 13.5002H3.85991C4.00163 12.1795 4.7001 10.9716 5.79375 10.1904Z"
        fill="#656565"
      />
    </g>
    <defs>
      <clipPath id="clip0_18955_130122">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
