import { useMemo } from 'react'

import { EllipsisOutlined } from '@ant-design/icons'
import { useWallet } from '@solana/wallet-adapter-react'
import classNames from 'classnames'

import { useDiscordUser } from '@banx/hooks'
import { Wallet } from '@banx/icons'
import { useBanxStakeInfo } from '@banx/pages/RootPage/hooks'
import { formatNumbersWithCommas, isAdventureLive, shortenAddress } from '@banx/utils'

import UserAvatar from '../UserAvatar'
import { useWalletModal } from '../WalletModal'
import { Button } from './Button'

import styles from './Buttons.module.less'

export const WalletConnectButton = () => {
  const { toggleVisibility } = useWalletModal()
  const { publicKey, connected } = useWallet()

  const { data: discordUserData } = useDiscordUser()

  const { banxStakeInfo } = useBanxStakeInfo()

  const banxAdventuresWithSubscription = useMemo(() => {
    return banxStakeInfo?.banxAdventures.find(({ adventure }) => isAdventureLive(adventure))
  }, [banxStakeInfo])

  const totalPointsStaked = banxAdventuresWithSubscription?.totalPointsStaked || 0
  const totalPointsStakedNumber = totalPointsStaked ? totalPointsStaked.toNumber() : 0

  const ConnectedButton = () => (
    <div className={styles.connectedButton} onClick={toggleVisibility}>
      <UserAvatar imageUrl={discordUserData?.avatarUrl ?? undefined} />
      <div className={styles.connectedWalletInfo}>
        <span className={styles.connectedWalletAddress}>
          {shortenAddress(publicKey?.toBase58() || '')}
        </span>
        <span
          className={styles.partnerPoints}
        >{`${formatNumbersWithCommas(totalPointsStakedNumber)} partner pts`}</span>
      </div>
      <EllipsisOutlined className={classNames(styles.connectedWalletElipsis)} />
    </div>
  )

  const DisconnectedButton = () => (
    <Button onClick={toggleVisibility} className={styles.disconnectedButton}>
      <Wallet className={styles.walletIcon} />
      <span>Connect wallet</span>
    </Button>
  )

  return connected ? <ConnectedButton /> : <DisconnectedButton />
}
