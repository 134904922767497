import { Loader } from '@banx/components/Loader'

import { GeneralProposalInfo } from './components/GeneralProposalInfo'
import { StatusInfoBlock } from './components/StatusInfoBlock'
import { VoteActionBlock } from './components/VoteActionBlock'
import { VotesInfoBlock } from './components/VotesInfoBlock'
import { useProposalPage } from './hooks'

import styles from './ProposalPage.module.less'

export const ProposalPage = () => {
  const { currentProposal, banxAdventuresWithSubscription, isLoading } = useProposalPage()

  const showContent = !isLoading && currentProposal && !!banxAdventuresWithSubscription

  return (
    <div className={styles.content}>
      {isLoading && <Loader />}

      {showContent && (
        <>
          <div className={styles.mainColumns}>
            <GeneralProposalInfo
              title={currentProposal.name}
              publicKey={currentProposal.publicKey}
            />
            <VoteActionBlock
              currentProposal={currentProposal}
              adventurePubkey={banxAdventuresWithSubscription.adventure.publicKey}
              adventureSubscriptionPubkey={
                banxAdventuresWithSubscription.adventureSubscription?.publicKey ?? ''
              }
            />
          </div>

          <div className={styles.additionalColumns}>
            <VotesInfoBlock data={currentProposal} />
            <StatusInfoBlock proposal={currentProposal} />
          </div>
        </>
      )}
    </div>
  )
}
