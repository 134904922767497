import { FC, Key, ReactNode } from 'react'

import { notification } from 'antd'
import classNames from 'classnames'
import { ConfirmTransactionErrorReason } from 'solana-transactions-executor'

import { Button } from '@banx/components/Buttons'
import { SolanaFMLink } from '@banx/components/SolanaLinks'

import { Copy } from '@banx/icons'

import { copyToClipboard } from '../common'
import { SnackbarType, enqueueSnackbar } from './'

import styles from './Snackbar.module.less'

export type CopyButtonProps = Partial<{
  label: string
  textToCopy: string
}>

export interface SnackMessageProps {
  message: string
  solanaExplorerPath?: string
}
export const SnackMessage: FC<SnackMessageProps> = ({ message, solanaExplorerPath }) => (
  <div className={styles.snackMessageWrapper}>
    {solanaExplorerPath && (
      <SolanaFMLink className={styles.solanaFMBtn} size="small" path={solanaExplorerPath} />
    )}
    {message}
  </div>
)

export interface SnackDescriptionProps {
  description: string | ReactNode
  type: SnackbarType
  copyButtonProps?: CopyButtonProps
}
export const SnackDescription: FC<SnackDescriptionProps> = ({
  description,
  type,
  copyButtonProps = {},
}) => {
  const { label: copyBtnLabel = 'Copy', textToCopy } = copyButtonProps

  const onBtnClick = () => {
    copyToClipboard(textToCopy || '')
    enqueueSnackbar({
      message: 'Copied to clipboard',
      autoHideDuration: 1,
    })
  }

  return (
    <div
      className={classNames(
        styles.snackDescriptionWrapper,
        styles[`snackDescriptionWrapper__${type}`],
      )}
    >
      {description}
      {!!textToCopy && (
        <Button onClick={onBtnClick} type="circle" variant="tertiary">
          <Copy />
          {copyBtnLabel}
        </Button>
      )}
    </div>
  )
}

export const destroySnackbar = (key?: Key) => notification.destroy(key)

export const enqueueTransactionSent = (signature: string) =>
  enqueueSnackbar({
    message: 'Transaction sent',
    type: 'info',
    solanaExplorerPath: `tx/${signature}`,
  })

export const enqueueWaitingConfirmation = (key: string) =>
  enqueueSnackbar({
    customKey: key,
    message: 'Waiting for confirmation',
    type: 'loading',
    persist: true,
  })

export const enqueueTranactionError = () =>
  enqueueSnackbar({
    message: 'Transaction failed. Please try again',
    type: 'error',
  })

export const enqueueTranactionsError = (count: number) =>
  enqueueSnackbar({
    message: `${count} transaction${count > 1 ? 's' : ''} failed. Please try again`,
    type: 'error',
  })

export const enqueueTransactionsSent = () =>
  enqueueSnackbar({
    message: 'Transactions sent',
    type: 'info',
  })

export const enqueueWaitingConfirmationSingle = (key: string, signature: string) => {
  enqueueSnackbar({
    customKey: key,
    message: 'Waiting for confirmation',
    type: 'loading',
    persist: true,
    solanaExplorerPath: `tx/${signature}`,
  })
}

export const enqueueConfirmationError = (
  signature: string,
  reason: ConfirmTransactionErrorReason,
) => {
  if (reason === ConfirmTransactionErrorReason.TimeoutError) {
    return enqueueSnackbar({
      message: 'Unable to ensure transaction result. Please try increasing priority fees.',
      type: 'warning',
      autoHideDuration: 7,
      solanaExplorerPath: `tx/${signature}`,
    })
  }
  return enqueueTranactionError()
}
