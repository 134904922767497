import { Button } from '@banx/components/Buttons'

import { useModal } from '@banx/store'

import ProposalsTable from '../RootPage/components/Proposals/ProposalsTable'
import { useAllGovernanceAccounts } from '../RootPage/components/Proposals/hooks'
import CreateProposalModal from './components'

import styles from './AdminPage.module.less'

export const AdminPage = () => {
  const { data, isLoading } = useAllGovernanceAccounts()

  const { open } = useModal()

  const showModal = () => {
    open(CreateProposalModal, { data })
  }

  return (
    <div className={styles.content}>
      <div className={styles.proposalsBlock}>
        <div className={styles.proposalsContentHeader}>
          <h2 className={styles.proposalsContentTitle}>Admin</h2>
          <Button onClick={showModal}>Create Proposal</Button>
        </div>

        <ProposalsTable data={data} isLoading={isLoading} isEdit />
      </div>
    </div>
  )
}
