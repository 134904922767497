import { web3 } from '@project-serum/anchor'
import { returnAnchorProgram } from 'fbonds-core/lib/fbond-protocol/helpers'

import { bufferToArray, encodeProposalName, getProposalVariantPubkey } from '../helpers'

type SaveVariant = (params: {
  connection: web3.Connection
  args: {
    name: string
  }

  accounts: {
    userPubkey: web3.PublicKey //? Admin pubkey (9J4yDqU6wBkdhP5bmJhukhsEzBkaAXiBmii52kTdxpQq)
    proposalPubkey: web3.PublicKey
  }

  sendTxn: (transaction: web3.Transaction, signers: web3.Signer[]) => Promise<void>
}) => Promise<{
  // fbond: web3.PublicKey;
  instructions: web3.TransactionInstruction[]
  signers: web3.Signer[]
}>

export const saveVariant: SaveVariant = async ({ connection, args, accounts, sendTxn }) => {
  const program = returnAnchorProgram(connection)

  const instructions: web3.TransactionInstruction[] = []
  instructions.push(
    await program.methods
      .saveVariant({
        name: bufferToArray(encodeProposalName(args)),
      })
      .accountsStrict({
        user: accounts.userPubkey,
        systemProgram: web3.SystemProgram.programId,
        rent: web3.SYSVAR_RENT_PUBKEY,
        proposal: accounts.proposalPubkey,
        proposalVariant: await getProposalVariantPubkey(program, args, accounts),
      })
      .instruction(),
  )

  const transaction = new web3.Transaction()
  for (const instruction of instructions) transaction.add(instruction)

  const signers: web3.Signer[] = []
  await sendTxn(transaction, signers)

  return {
    instructions,
    signers,
  }
}
