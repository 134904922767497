import React, { FC, useMemo, useState } from 'react'

import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { uniqueId } from 'lodash'
import { TxnExecutor } from 'solana-transactions-executor'

import { Button } from '@banx/components/Buttons'
import { Input } from '@banx/components/inputs/Input'
import { Modal } from '@banx/components/modals/BaseModal'

import { useModal } from '@banx/store'
import {
  TXN_EXECUTOR_DEFAULT_OPTIONS,
  createExecutorWalletAndConnection,
  defaultTxnErrorHandler,
} from '@banx/transactions'
import {
  CreateProposalVariantTxnDataParams,
  createProposalVariantTxnData,
} from '@banx/transactions/governance/createProposalVariantTxnData'
import { enqueueSnackbar } from '@banx/utils'
import {
  destroySnackbar,
  enqueueConfirmationError,
  enqueueTransactionsSent,
  enqueueWaitingConfirmation,
} from '@banx/utils/snackbar/components'

import { MergedProposal } from '../../hooks'

import styles from '../ProposalsTable.module.less'

interface AdminActionCellProps {
  data: MergedProposal
}

const AdminActionCell: FC<AdminActionCellProps> = ({ data }) => {
  const { open } = useModal()

  const showModal = () => {
    open(ManageModal, { data })
  }

  return (
    <Button onClick={showModal} size="medium" variant="secondary">
      Manage
    </Button>
  )
}

export default AdminActionCell

interface ManageModalProps {
  data: MergedProposal
}

const ManageModal: FC<ManageModalProps> = ({ data }) => {
  const wallet = useWallet()
  const { connection } = useConnection()

  const { close } = useModal()

  const { publicKey: proposalPubkey, variants } = data

  const [firstVariant, setFirstVariant] = useState(variants[0]?.name || '')
  const [secondVariant, setSecondVariant] = useState(variants[1]?.name || '')
  const [thirdVariant, setThirdVariant] = useState(variants[2]?.name || '')
  const [fourthVariant, setFourthVariant] = useState(variants[3]?.name || '')

  const areAllInputsEmpty = [firstVariant, secondVariant, thirdVariant, fourthVariant].every(
    (variant) => !variant.trim(),
  )

  const enabledVariants = useMemo(() => {
    return [
      { name: firstVariant, isDisabled: !!variants[0]?.name },
      { name: secondVariant, isDisabled: !!variants[1]?.name },
      { name: thirdVariant, isDisabled: !!variants[2]?.name },
      { name: fourthVariant, isDisabled: !!variants[3]?.name },
    ].filter(({ name, isDisabled }) => !isDisabled && name.trim())
  }, [firstVariant, secondVariant, thirdVariant, fourthVariant, variants])

  const createProposalVariant = async () => {
    const loadingSnackbarId = uniqueId()

    try {
      const walletAndConnection = createExecutorWalletAndConnection({ wallet, connection })

      const txnsData = await Promise.all(
        enabledVariants.map((variant) =>
          createProposalVariantTxnData({ name: variant.name, proposalPubkey }, walletAndConnection),
        ),
      )

      await new TxnExecutor<CreateProposalVariantTxnDataParams>(
        walletAndConnection,
        TXN_EXECUTOR_DEFAULT_OPTIONS,
      )
        .addTxnsData(txnsData)
        .on('sentAll', () => {
          enqueueTransactionsSent()
          enqueueWaitingConfirmation(loadingSnackbarId)
        })
        .on('confirmedAll', (results) => {
          const { confirmed, failed } = results

          destroySnackbar(loadingSnackbarId)

          if (confirmed.length) {
            enqueueSnackbar({ message: 'Variants successfully created', type: 'success' })
            close()

            confirmed.forEach(({ accountInfoByPubkey }) => {
              if (!accountInfoByPubkey) return
            })
          }

          if (failed.length) {
            return failed.forEach(({ signature, reason }) =>
              enqueueConfirmationError(signature, reason),
            )
          }
        })
        .on('error', (error) => {
          throw error
        })
        .execute()
    } catch (error) {
      destroySnackbar(loadingSnackbarId)
      defaultTxnErrorHandler(error, {
        walletPubkey: wallet?.publicKey?.toBase58(),
        transactionName: 'CreateVariant',
      })
    }
  }

  return (
    <Modal open centered maskClosable={false} width={460} footer={false} onCancel={close}>
      <div className={styles.manageModalContent}>
        <div className={styles.inputWrapper}>
          <span>First variant</span>
          <Input
            placeholder="Name"
            value={firstVariant}
            onChange={(e) => setFirstVariant(e.target.value)}
            disabled={!!variants[0]?.name}
          />
        </div>
        <div className={styles.inputWrapper}>
          <span>Second variant</span>
          <Input
            placeholder="Name"
            value={secondVariant}
            onChange={(e) => setSecondVariant(e.target.value)}
            disabled={!!variants[1]?.name}
          />
        </div>

        <div className={styles.inputWrapper}>
          <span>Third variant</span>
          <Input
            placeholder="Name"
            value={thirdVariant}
            onChange={(e) => setThirdVariant(e.target.value)}
            disabled={!!variants[2]?.name}
          />
        </div>

        <div className={styles.inputWrapper}>
          <span>Fourth variant</span>
          <Input
            placeholder="Name"
            value={fourthVariant}
            onChange={(e) => setFourthVariant(e.target.value)}
            disabled={!!variants[3]?.name}
          />
        </div>

        <Button
          onClick={createProposalVariant}
          disabled={areAllInputsEmpty || enabledVariants.length === 0}
          className={styles.createVariantButton}
        >
          Create variants
        </Button>
      </div>
    </Modal>
  )
}
