import { useWallet } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'

import { fetchBanxStakeInfo } from '@banx/api/core'

export const useBanxStakeInfo = () => {
  const { publicKey } = useWallet()
  const walletPubkey = publicKey?.toBase58() || ''

  const { data: banxStakeInfo, isLoading } = useQuery(
    ['fetchBanxStakeInfo', walletPubkey],
    () => fetchBanxStakeInfo({ userPubkey: walletPubkey }),
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
    },
  )

  return {
    banxStakeInfo,
    isLoading,
  }
}
