import BN from 'bn.js'
import { z } from 'zod'

export const SerializedBNSchema = z.string().transform((value) => {
  return new BN(value)
})

export const StringIntSchema = z.string().transform((value) => {
  return parseInt(value)
})

export const StringPublicKeySchema = z.string()

enum BanxAdventureState {
  None = 'none',
  Active = 'active',
}

enum BanxTokenStakeState {
  None = 'none',
  Staked = 'staked',
  Unstaked = 'unstaked',
}

enum BanxStakeState {
  Staked = 'staked',
  Unstaked = 'unstaked',
  Liquidated = 'liquidated',
}

enum BanxAdventureSubscriptionState {
  None = 'none',
  Active = 'active',
  Claimed = 'claimed',
}

export const PointsMapSchema = z.object({
  publicKey: StringPublicKeySchema,
  banxMint: StringPublicKeySchema,
  partnerPoints: StringIntSchema,
  playerPoints: StringIntSchema,
})

export const BanxStakeSchema = z.object({
  publicKey: StringPublicKeySchema,
  adventureSubscriptionsQuantity: SerializedBNSchema,
  banxStakeState: z.nativeEnum(BanxStakeState),
  bond: StringPublicKeySchema,
  collateralTokenAccount: StringPublicKeySchema,
  farmedAmount: SerializedBNSchema,
  isLoaned: z.boolean(),
  isTerminationFreeze: z.boolean().optional(),
  nftMint: StringPublicKeySchema,
  partnerPoints: SerializedBNSchema,
  playerPoints: SerializedBNSchema,
  stakedAt: SerializedBNSchema,
  unstakedOrLiquidatedAt: SerializedBNSchema,
  user: StringPublicKeySchema,
})

export const BanxNftStakeSchema = z.object({
  mint: StringPublicKeySchema,
  meta: z.object({
    imageUrl: z.string(),
    mint: StringPublicKeySchema,
    name: z.string(),
    partnerPoints: z.number(),
    playerPoints: z.number(),
    rarity: z.string(),
  }),
  isLoaned: z.boolean(),
  isTerminationFreeze: z.boolean(),
  pointsMap: PointsMapSchema,
  stake: BanxStakeSchema.optional(),
})

export const BanxAdventureSubscriptionSchema = z.object({
  adventureSubscriptionState: z.nativeEnum(BanxAdventureSubscriptionState),
  user: StringPublicKeySchema,
  adventure: StringPublicKeySchema,
  banxTokenStake: StringPublicKeySchema,
  stakeTokensAmount: SerializedBNSchema,
  stakePartnerPointsAmount: SerializedBNSchema,
  stakePlayerPointsAmount: SerializedBNSchema,
  subscribedAt: SerializedBNSchema,
  unsubscribedAt: SerializedBNSchema,
  harvestedAt: SerializedBNSchema,
  amountOfTokensHarvested: SerializedBNSchema,
  publicKey: StringPublicKeySchema,
  stakeNftAmount: SerializedBNSchema,
  amountOfHadesTokensHarvested: SerializedBNSchema,
})

export const BanxAdventureSchema = z.object({
  adventureState: z.nativeEnum(BanxAdventureState),
  week: SerializedBNSchema,
  amountOfTokensHarvested: SerializedBNSchema,
  periodEndingAt: SerializedBNSchema,
  periodStartedAt: SerializedBNSchema,
  publicKey: StringPublicKeySchema,
  rewardsToBeDistributed: SerializedBNSchema,
  tokensPerPoints: SerializedBNSchema,
  totalBanxSubscribed: SerializedBNSchema,
  totalPartnerPoints: SerializedBNSchema,
  totalPlayerPoints: SerializedBNSchema,
  totalTokensStaked: SerializedBNSchema,
})

export const BanxTokenStakeSchema = z.object({
  banxNftsStakedQuantity: SerializedBNSchema,
  partnerPointsStaked: SerializedBNSchema,
  playerPointsStaked: SerializedBNSchema,
  banxStakeState: z.nativeEnum(BanxTokenStakeState),
  stakedAt: SerializedBNSchema,
  user: StringPublicKeySchema,
  adventureSubscriptionsQuantity: SerializedBNSchema,
  tokensStaked: SerializedBNSchema,
  unstakedAt: SerializedBNSchema,
  farmedAmount: SerializedBNSchema,
  publicKey: StringPublicKeySchema,
  nftsStakedAt: SerializedBNSchema,
  nftsUnstakedAt: SerializedBNSchema,
})

export const BanxAdventuresWithSubscriptionSchema = z.object({
  adventure: BanxAdventureSchema,
  adventureSubscription: BanxAdventureSubscriptionSchema.optional(),
  totalPointsStaked: SerializedBNSchema,
})

export const BanxStakingInfoSchema = z.object({
  banxWalletBalance: SerializedBNSchema.nullable(),
  banxTokenStake: BanxTokenStakeSchema.nullable(),
  banxAdventures: BanxAdventuresWithSubscriptionSchema.array(),
  nfts: BanxNftStakeSchema.array().nullable().optional(),
})

export type BanxStake = z.infer<typeof BanxStakeSchema>

export type BanxNftStake = z.infer<typeof BanxNftStakeSchema>

export type BanxAdventureSubscription = z.infer<typeof BanxAdventureSubscriptionSchema>

export type BanxAdventure = z.infer<typeof BanxAdventureSchema>

export type BanxTokenStake = z.infer<typeof BanxTokenStakeSchema>

export type BanxAdventuresWithSubscription = z.infer<typeof BanxAdventuresWithSubscriptionSchema>

export type BanxStakingInfo = z.infer<typeof BanxStakingInfoSchema>
