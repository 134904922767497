import { web3 } from 'banx-claim-sdk/lib/utils'
import { CreateTxnData, WalletAndConnection } from 'solana-transactions-executor'

import { createVote } from '@banx/governance-sdk/functions/vote'
import { sendTxnPlaceHolder } from '@banx/utils'

export type CreateVoteTxnDataParams = {
  proposalPubkey: string
  proposalVariantPubkey: string
  adventurePubkey: string
  adventureSubscriptionPubkey: string
}

type CreateVoteTxnData = (
  params: CreateVoteTxnDataParams,
  walletAndConnection: WalletAndConnection,
) => Promise<CreateTxnData<CreateVoteTxnDataParams>>

export const createVoteTxnData: CreateVoteTxnData = async (params, walletAndConnection) => {
  const { proposalPubkey, proposalVariantPubkey, adventurePubkey, adventureSubscriptionPubkey } =
    params

  const { instructions, signers } = await createVote({
    accounts: {
      adventurePubkey: new web3.PublicKey(adventurePubkey),
      adventureSubscriptionPubkey: new web3.PublicKey(adventureSubscriptionPubkey),
      userPubkey: walletAndConnection.wallet.publicKey,
      proposalPubkey: new web3.PublicKey(proposalPubkey),
      proposalVariantPubkey: new web3.PublicKey(proposalVariantPubkey),
    },
    connection: walletAndConnection.connection,
    sendTxn: sendTxnPlaceHolder,
  })

  return {
    params,
    accounts: [],
    instructions,
    signers,
    lookupTables: [],
  }
}
