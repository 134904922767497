import React, { FC } from 'react'

import classNames from 'classnames'
import { upperFirst } from 'lodash'

import { isProposalEnded } from '../../helpers'
import { MergedProposal } from '../Proposals/hooks'

import styles from './VoteStatusBadge.module.less'

enum VoteStatus {
  Voting = 'voting',
  Completed = 'completed',
}

interface VoteStatusBadgeProps {
  proposal: MergedProposal
  className?: string
}

const VoteStatusBadge: FC<VoteStatusBadgeProps> = ({ proposal, className }) => {
  const status = isProposalEnded(proposal) ? VoteStatus.Completed : VoteStatus.Voting
  const statusClass = `status-${status}`

  return (
    <div className={classNames(styles.badge, styles[statusClass], className)}>
      {upperFirst(status)}
    </div>
  )
}

export default VoteStatusBadge
