import { FC } from 'react'

import { ProposalPage, RootPage } from '@banx/pages'
import { AdminPage } from '@banx/pages/AdminPage'

import { PATHS } from './paths'

interface Route {
  path: string
  component: FC
}

export const routes: Route[] = [
  {
    path: PATHS.ROOT,
    component: RootPage,
  },
  {
    path: `${PATHS.PROPOSAL}/:id`,
    component: ProposalPage,
  },
  {
    path: PATHS.ADMIN,
    component: AdminPage,
  },
  {
    path: PATHS.PAGE_404,
    component: RootPage,
  },
  {
    path: '*',
    component: RootPage,
  },
]
