import { TxnError } from 'solana-transactions-executor'

import { captureSentryTxnError } from '@banx/utils'

import { enqueueTxnErrorSnackbar } from './enqueueErrorSnackbar'

type DefaultTxnErrorHandler = (
  error: unknown,
  options?: Partial<{
    additionalData: unknown
    walletPubkey: string
    transactionName: string
  }>,
) => void

export const defaultTxnErrorHandler: DefaultTxnErrorHandler = (error, options = {}) => {
  console.error(error)

  if (error instanceof Error) {
    enqueueTxnErrorSnackbar(error, options)
    //? If error has logs --> print them
    console.error(errorLogsToString(error))
  }

  const { walletPubkey, additionalData, transactionName } = options
  captureSentryTxnError({ error, additionalData, walletPubkey, transactionName })
}

export const errorLogsToString = (error: unknown) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (error as any)?.logs?.join('\n') ?? ''
}

type CreateErrorLogsString = (
  error: TxnError,
  options?: Partial<{
    additionalData: unknown
    walletPubkey: string
    transactionName: string
  }>,
) => string
export const createErrorLogsString: CreateErrorLogsString = (error, options) => {
  return JSON.stringify(
    {
      transaction: options?.transactionName ?? '',
      wallet: options?.walletPubkey ?? '',
      message: error?.message,
      logs: error?.logs ?? [],
      additional: options?.additionalData,
    },
    null,
    2,
  )
}
