import { FC, useEffect, useMemo, useState } from 'react'

import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import classNames from 'classnames'
import { uniqueId } from 'lodash'
import { TxnExecutor } from 'solana-transactions-executor'

import { Button, WalletConnectButton } from '@banx/components/Buttons'

import { MergedProposal } from '@banx/pages/RootPage/components/Proposals/hooks'
import { isProposalEnded } from '@banx/pages/RootPage/helpers'
import {
  TXN_EXECUTOR_DEFAULT_OPTIONS,
  createExecutorWalletAndConnection,
  defaultTxnErrorHandler,
} from '@banx/transactions'
import {
  CreateVoteTxnDataParams,
  createVoteTxnData,
} from '@banx/transactions/governance/createVoteTxnData'
import { destroySnackbar, enqueueSnackbar } from '@banx/utils'
import {
  enqueueConfirmationError,
  enqueueTransactionSent,
  enqueueWaitingConfirmation,
} from '@banx/utils/snackbar/components'

import styles from '../ProposalPage.module.less'

interface VoteActionBlockProps {
  adventurePubkey: string
  adventureSubscriptionPubkey: string
  currentProposal: MergedProposal
}

export const VoteActionBlock: FC<VoteActionBlockProps> = ({
  adventurePubkey,
  adventureSubscriptionPubkey,
  currentProposal,
}) => {
  const wallet = useWallet()
  const { connection } = useConnection()

  const [selectedVariantPubkey, setSelectedVariantPubkey] = useState('')

  const userVotedVariant = useMemo(() => {
    return currentProposal.variants.find((variant) =>
      variant.votes.some((vote) => vote.user === wallet.publicKey?.toBase58()),
    )
  }, [currentProposal, wallet.publicKey])

  useEffect(() => {
    if (selectedVariantPubkey) return

    if (userVotedVariant) {
      setSelectedVariantPubkey(userVotedVariant.publicKey)
    } else {
      setSelectedVariantPubkey(currentProposal.variants[0].publicKey)
    }
  }, [currentProposal, selectedVariantPubkey, userVotedVariant])

  const vote = async () => {
    const loadingSnackbarId = uniqueId()

    try {
      const walletAndConnection = createExecutorWalletAndConnection({ wallet, connection })

      const txnData = await createVoteTxnData(
        {
          adventurePubkey,
          adventureSubscriptionPubkey,
          proposalPubkey: currentProposal.publicKey,
          proposalVariantPubkey: selectedVariantPubkey,
        },
        walletAndConnection,
      )

      await new TxnExecutor<CreateVoteTxnDataParams>(
        walletAndConnection,
        TXN_EXECUTOR_DEFAULT_OPTIONS,
      )
        .addTxnData(txnData)
        .on('sentSome', (results) => {
          results.forEach(({ signature }) => enqueueTransactionSent(signature))
          enqueueWaitingConfirmation(loadingSnackbarId)
        })
        .on('confirmedAll', (results) => {
          const { confirmed, failed } = results

          destroySnackbar(loadingSnackbarId)

          if (failed.length) {
            return failed.forEach(({ signature, reason }) =>
              enqueueConfirmationError(signature, reason),
            )
          }

          return confirmed.forEach(({ accountInfoByPubkey, signature }) => {
            if (accountInfoByPubkey) {
              enqueueSnackbar({
                message: 'Successfully voted',
                type: 'success',
                solanaExplorerPath: `tx/${signature}`,
              })
            }
          })
        })
        .on('error', (error) => {
          throw error
        })
        .execute()
    } catch (error) {
      destroySnackbar(loadingSnackbarId)
      defaultTxnErrorHandler(error, {
        walletPubkey: wallet?.publicKey?.toBase58(),
        transactionName: 'Vote',
      })
    }
  }

  return (
    <div className={styles.contentBlock}>
      <div className={styles.variantControls}>
        {currentProposal.variants.map((variant) => (
          <Button
            key={variant.publicKey}
            type="circle"
            variant="tertiary"
            onClick={() => setSelectedVariantPubkey(variant.publicKey)}
            disabled={userVotedVariant && userVotedVariant.publicKey !== variant.publicKey}
            className={classNames(styles.variantControlsItem, {
              [styles.active]: variant.publicKey === selectedVariantPubkey,
            })}
          >
            {variant.name}
          </Button>
        ))}
      </div>

      <div className={styles.actionVoteButton}>
        {wallet.connected ? (
          <Button onClick={vote} disabled={!!userVotedVariant || isProposalEnded(currentProposal)}>
            Vote
          </Button>
        ) : (
          <WalletConnectButton />
        )}
      </div>
    </div>
  )
}
