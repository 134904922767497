import { CreateTxnData, WalletAndConnection } from 'solana-transactions-executor'

import { saveProposal } from '@banx/governance-sdk/functions/proposal'
import { sendTxnPlaceHolder } from '@banx/utils'

export type CreateProposalTxnDataParams = {
  name: string //? Proposal name
  week: number //? Staking week (current - n)
  duration: number //? timestamp in seconds
  pointsQuorum: number //? min amount to execute proposal
}

type CreateProposalTxnData = (
  params: CreateProposalTxnDataParams,
  walletAndConnection: WalletAndConnection,
) => Promise<CreateTxnData<CreateProposalTxnDataParams>>

export const createProposalTxnData: CreateProposalTxnData = async (params, walletAndConnection) => {
  const { name, week, duration, pointsQuorum } = params

  const { instructions, signers } = await saveProposal({
    args: {
      name,
      week,
      duration,
      pointsQuorum,
    },
    accounts: {
      userPubkey: walletAndConnection.wallet.publicKey,
    },
    connection: walletAndConnection.connection,
    sendTxn: sendTxnPlaceHolder,
  })

  return {
    params,
    accounts: [],
    instructions,
    signers,
    lookupTables: [],
  }
}
