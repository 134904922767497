import { FC, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import classNames from 'classnames'

import { Button, WalletConnectButton } from '@banx/components/Buttons'

import { BanxAdventuresWithSubscription } from '@banx/api/core'
import { BANX_BASE, BANX_STAKE_URL, DISCORD, DOCS_URL, X_URL } from '@banx/constants'
import { Discord, Docs, Globe, Twitter } from '@banx/icons'
import { formatNumbersWithCommas, isAdventureLive } from '@banx/utils'

import Proposals from './components/Proposals'
import { useBanxStakeInfo } from './hooks'

import styles from './RootPage.module.less'

export const RootPage = () => {
  return <Content />
}

const Content = () => {
  const { banxStakeInfo } = useBanxStakeInfo()

  const banxAdventuresWithSubscription = useMemo(() => {
    return banxStakeInfo?.banxAdventures.find(({ adventure }) => isAdventureLive(adventure))
  }, [banxStakeInfo])

  return (
    <div className={styles.content}>
      <MainBlock />
      <div className={styles.twoColumns}>
        <VoteInfoBlock banxAdventuresWithSubscription={banxAdventuresWithSubscription} />
        <StakingBlock />
      </div>
      <Proposals />
    </div>
  )
}

const MainBlock = () => {
  const EXTERNAL_LINKS = [
    {
      label: 'X',
      href: X_URL,
      icon: Twitter,
    },
    {
      label: 'Discord',
      href: DISCORD.SERVER_URL,
      icon: Discord,
    },
    {
      label: 'Docs',
      href: DOCS_URL,
      icon: Docs,
    },
    {
      label: 'Website',
      href: BANX_BASE,
      icon: Globe,
    },
  ]

  return (
    <div className={styles.contentBlock}>
      <div className={styles.mainBlockColumn}>
        <h2 className={classNames(styles.contentTitle, styles.mainBlockTitle)}>Banx voting</h2>
        <ul className={styles.mainBlockText}>
          <li>
            Partner points are allocated immediately when you stake Banx NFT or $BANX, your voting
            power equals partner points staked during proposals specified week
          </li>
          <li>You can use your voting power only on one vote in proposal</li>
        </ul>
        <div className={styles.mainBlockIcons}>
          {EXTERNAL_LINKS.map(({ icon: Icon, href, label }) => (
            <a key={label} href={href} rel="noopener noreferrer" target="_blank">
              {Icon && <Icon />}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const StakingBlock = () => {
  return (
    <div className={classNames(styles.contentBlock, styles.stakingBlock)}>
      <h2 className={classNames(styles.stakingBlockTitle)}>
        Want to increase your voting power? Stake more Banx NFTs or $BANX
      </h2>
      <a
        href={BANX_STAKE_URL}
        rel="noopener noreferrer"
        target="_blank"
        className={styles.stakingBlockBtn}
      >
        <Button variant="secondary" size="medium">
          Stake more
        </Button>
      </a>
    </div>
  )
}

interface VoteInfoBlockProps {
  banxAdventuresWithSubscription: BanxAdventuresWithSubscription | undefined
}

const VoteInfoBlock: FC<VoteInfoBlockProps> = ({ banxAdventuresWithSubscription }) => {
  const { connected } = useWallet()

  const { adventure, totalPointsStaked } = banxAdventuresWithSubscription || {}
  const week = adventure?.week.toNumber() || 0

  const totalPointsStakedNumber = totalPointsStaked ? totalPointsStaked.toNumber() : 0

  return (
    <div className={classNames(styles.contentBlock, styles.voteInfoBlock)}>
      <h2 className={classNames(styles.contentTitle, styles.voteBlockTitle)}>Voting power</h2>

      {!connected && (
        <div className={styles.voteBlockNotConnected}>
          <span>Please connect wallet to see your partner points</span>
          <WalletConnectButton />
        </div>
      )}

      {connected && (
        <div className={styles.voteBlockStats}>
          <span>{formatNumbersWithCommas(totalPointsStakedNumber)}</span>
          <span>Staked partner pts, week {week}</span>
        </div>
      )}
    </div>
  )
}
