import axios from 'axios'
import { web3 } from 'fbonds-core'

import { BACKEND_BASE_URL } from '@banx/constants'
import { getDiscordAvatarUrl } from '@banx/utils'

import { DiscordUserInfo, DiscordUserInfoRaw } from './types'

type FetchDiscordUser = (props: { publicKey: web3.PublicKey }) => Promise<DiscordUserInfo | null>
export const fetchDiscordUser: FetchDiscordUser = async ({ publicKey }) => {
  const { data } = await axios.get<DiscordUserInfoRaw>(
    `${BACKEND_BASE_URL}/discord/${publicKey.toBase58()}`,
  )

  if (!data) return null

  const avatarUrl = getDiscordAvatarUrl(data.discordId, data.avatar)

  if (!avatarUrl) return null

  const { data: avatarExists } = await axios.get<string>(avatarUrl)

  return {
    avatarUrl: avatarExists ? avatarUrl : null,
    isOnServer: data?.isOnServer ?? false,
  }
}

type RemoveDiscordUser = (props: { publicKey: web3.PublicKey }) => Promise<void>
export const removeDiscordUser: RemoveDiscordUser = async ({ publicKey }) => {
  await axios.get(`${BACKEND_BASE_URL}/discord/${publicKey.toBase58()}/delete`)
}
