import { Button, WalletConnectButton } from '@banx/components/Buttons'
import { WalletModal, useWalletModal } from '@banx/components/WalletModal'

import { BANX_BASE, BANX_STAKE_URL } from '@banx/constants'
import { Logo, LogoFull } from '@banx/icons'

import styles from './Header.module.less'

export const Header = () => {
  const { visible } = useWalletModal()

  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        <a
          href={BANX_BASE}
          className={styles.logoWrapper}
          rel="noopener noreferrer"
          target="_blank"
        >
          <LogoFull className={styles.logo} />
          <Logo className={styles.logoMobile} />
        </a>
        <div className={styles.widgetContainer}>
          <StakeButton />
          <WalletConnectButton />
        </div>
        {visible && <WalletModal />}
      </div>
    </div>
  )
}

const StakeButton = () => {
  return (
    <a href={BANX_STAKE_URL} rel="noopener noreferrer" target="_blank">
      <Button type="circle" variant="tertiary" className={styles.rewardsButton}>
        <div className={styles.rewardsButtonText}>Stake $BANX</div>
      </Button>
    </a>
  )
}
