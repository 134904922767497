import { BN, web3 } from '@project-serum/anchor'
import { returnAnchorProgram } from 'fbonds-core/lib/fbond-protocol/helpers'

import { bufferToArray, encodeProposalName, getProposalPubkey } from '../helpers'

type SaveProposal = (params: {
  connection: web3.Connection
  args: {
    name: string //? Proposal name
    week: number //? Staking week (current - n)
    duration: number //? timestamp in seconds
    pointsQuorum: number //? min amount to execute proposal
  }

  accounts: {
    userPubkey: web3.PublicKey //? Admin pubkey (9J4yDqU6wBkdhP5bmJhukhsEzBkaAXiBmii52kTdxpQq)
  }

  sendTxn: (transaction: web3.Transaction, signers: web3.Signer[]) => Promise<void>
}) => Promise<{
  instructions: web3.TransactionInstruction[]
  signers: web3.Signer[]
}>

export const saveProposal: SaveProposal = async ({ connection, args, accounts, sendTxn }) => {
  const program = returnAnchorProgram(connection)

  const instructions: web3.TransactionInstruction[] = []
  instructions.push(
    await program.methods
      .saveProposal({
        name: bufferToArray(encodeProposalName(args)),
        week: new BN(args.week),
        duration: new BN(args.duration),
        pointsQuorum: new BN(args.pointsQuorum),
      })
      .accountsStrict({
        user: accounts.userPubkey,
        systemProgram: web3.SystemProgram.programId,
        rent: web3.SYSVAR_RENT_PUBKEY,
        proposal: await getProposalPubkey(program, args),
      })
      .instruction(),
  )

  const transaction = new web3.Transaction()
  for (const instruction of instructions) transaction.add(instruction)

  const signers: web3.Signer[] = []
  await sendTxn(transaction, signers)

  return {
    instructions,
    signers,
  }
}
