import moment from 'moment'

import { MergedProposal } from './components/Proposals/hooks'

export const isProposalEnded = (proposal: MergedProposal) => {
  const { initializedAt, duration } = proposal

  const currentTimeUnix = moment().unix()

  return currentTimeUnix > initializedAt + duration
}
