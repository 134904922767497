import { useMemo } from 'react'

import { useConnection } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'
import { filter, map, omit } from 'lodash'

import { getAllGovernanceAccounts } from '@banx/governance-sdk/functions/getters'
import { Proposal, ProposalVariant, ProposalVote } from '@banx/governance-sdk/types'

const deprecatedPublicKeys = [
  '6BR6c23Eu5x8f63aruWvfXArEXFyrQ5Ko5z9qQAwzYYa',
  '7ApDUC6SEYMGneFjUcxvhcAQjBdp8qqvNarhTJzzAEaf',
  '7X42BMrFm25Rsj8zwSvr7QidbVE81nvKEVeFhv45KSE4',
  'EPREMgbUpQKpHweptUHsCBhoSZu7bd94Vgkv8kgMf1qC',
  '6HmvyH21TAsHKUXZTCMjhQCkEMQnTouw2pJ9jKHRwP83'
]

export const useAllGovernanceAccounts = () => {
  const { connection } = useConnection()

  const { data, isLoading } = useQuery(
    ['governanceAccounts'],
    () => getAllGovernanceAccounts(connection),
    {
      staleTime: 60_000, //? 1 min
      cacheTime: 300_000, //? 5 min
      refetchOnWindowFocus: false,
    },
  )

  const mergedData = useMemo(() => {
    if (!data) return []

    return mergeProposalData(data)
      .sort((a, b) => b.initializedAt - a.initializedAt)
      .filter((p) => !deprecatedPublicKeys.includes(p.publicKey))
  }, [data])

  return {
    data: mergedData,
    isLoading,
  }
}

export interface MergedVariant extends ProposalVariant {
  votes: ProposalVote[]
}

export interface MergedProposal extends Proposal {
  variants: MergedVariant[]
}

const mergeProposalData = (data: {
  proposals: Proposal[]
  variants: ProposalVariant[]
  votes: ProposalVote[]
}): MergedProposal[] => {
  const { proposals, variants, votes } = data

  const omitFields = ['placeholder1', 'placeholder2', 'placeholder3']

  return map(proposals, (proposal) => {
    const relatedVariants = filter(variants, { proposal: proposal.publicKey })

    const cleanedProposal = omit(proposal, omitFields)

    const cleanedVariants = map(relatedVariants, (variant) => {
      const relatedVotes = filter(votes, { proposalVariant: variant.publicKey })

      return {
        ...omit(variant, omitFields),
        votes: relatedVotes,
      }
    })

    return {
      ...cleanedProposal,
      variants: cleanedVariants,
    } as MergedProposal
  })
}
