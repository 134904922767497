import { useState } from 'react'

import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { uniqueId } from 'lodash'
import { TxnExecutor } from 'solana-transactions-executor'

import { Button } from '@banx/components/Buttons'
import { Input } from '@banx/components/inputs/Input'
import NumericInput from '@banx/components/inputs/NumericInput'
import { Modal } from '@banx/components/modals/BaseModal'

import { useModal } from '@banx/store'
import { TXN_EXECUTOR_DEFAULT_OPTIONS, createExecutorWalletAndConnection } from '@banx/transactions'
import {
  CreateProposalTxnDataParams,
  createProposalTxnData,
} from '@banx/transactions/governance/createProposalTxnData'
import { destroySnackbar, enqueueSnackbar } from '@banx/utils'
import {
  enqueueConfirmationError,
  enqueueTransactionSent,
  enqueueWaitingConfirmation,
} from '@banx/utils/snackbar/components'

import styles from './AdminPage.module.less'

const CreateProposalModal = () => {
  const wallet = useWallet()
  const { connection } = useConnection()

  const { close } = useModal()

  const [proposalName, setProposalName] = useState('')
  const [proposalWeek, setProposalWeek] = useState('')
  const [duration, setDuration] = useState('')
  const [pointsQuorum, setPointsQuorum] = useState('100')

  const isAnyInputEmpty = [proposalName, proposalWeek, duration, pointsQuorum].some(
    (input) => !input.trim(),
  )

  const createProposal = async () => {
    const loadingSnackbarId = uniqueId()

    try {
      const walletAndConnection = createExecutorWalletAndConnection({ wallet, connection })

      const txnData = await createProposalTxnData(
        {
          name: proposalName,
          week: parseFloat(proposalWeek),
          duration: parseFloat(duration) * 3600,
          pointsQuorum: parseFloat(pointsQuorum),
        },
        walletAndConnection,
      )

      await new TxnExecutor<CreateProposalTxnDataParams>(
        walletAndConnection,
        TXN_EXECUTOR_DEFAULT_OPTIONS,
      )
        .addTxnData(txnData)
        .on('sentSome', (results) => {
          results.forEach(({ signature }) => enqueueTransactionSent(signature))
          enqueueWaitingConfirmation(loadingSnackbarId)
        })
        .on('confirmedAll', (results) => {
          const { confirmed, failed } = results

          destroySnackbar(loadingSnackbarId)

          if (failed.length) {
            return failed.forEach(({ signature, reason }) =>
              enqueueConfirmationError(signature, reason),
            )
          }

          return confirmed.forEach(({ accountInfoByPubkey, signature }) => {
            if (accountInfoByPubkey) {
              enqueueSnackbar({
                message: 'Proposal successfully created',
                type: 'success',
                solanaExplorerPath: `tx/${signature}`,
              })
            }
            close()
          })
        })
        .on('error', (error) => {
          throw error
        })
        .execute()
    } catch (error) {
      destroySnackbar(loadingSnackbarId)
    }
  }

  return (
    <Modal open centered maskClosable={false} width={460} footer={false} onCancel={close}>
      <div className={styles.createModalContent}>
        <div className={styles.inputWrapper}>
          <span>Name</span>
          <Input
            placeholder="Name"
            value={proposalName}
            onChange={(e) => setProposalName(e.target.value)}
          />
        </div>

        <div className={styles.inputWrapper}>
          <span>Week</span>
          <NumericInput placeholder="0" value={proposalWeek} onChange={setProposalWeek} />
        </div>

        <div className={styles.inputWrapper}>
          <span>Duration (hours)</span>
          <NumericInput placeholder="0" value={duration} onChange={setDuration} />
        </div>

        <div className={styles.inputWrapper}>
          <span>Min amount to execute proposal</span>
          <NumericInput value={pointsQuorum} onChange={setPointsQuorum} />
        </div>

        <Button
          className={styles.createProposalButton}
          onClick={createProposal}
          disabled={isAnyInputEmpty}
        >
          Create proposal
        </Button>
      </div>
    </Modal>
  )
}

export default CreateProposalModal
