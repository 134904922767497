import { FC, useCallback, useMemo } from 'react'

import { useWallet } from '@solana/wallet-adapter-react'
import { useNavigate } from 'react-router-dom'

import Table from '@banx/components/Table/Table'

import { PATHS } from '@banx/router'

import { MergedProposal } from '../hooks'
import { getTableColumns } from './columns'

import styles from './ProposalsTable.module.less'

interface ProposalsTableProps {
  data: MergedProposal[]
  isLoading: boolean
  isEdit?: boolean
}

const ProposalsTable: FC<ProposalsTableProps> = ({ data, isLoading, isEdit }) => {
  const { publicKey } = useWallet()
  const walletPubkey = publicKey?.toBase58() || ''

  const navigate = useNavigate()
  const columns = getTableColumns({ isEdit, walletPubkey })

  const onRowClick = useCallback(
    (data: MergedProposal) => {
      if (isEdit) return

      return navigate(`${PATHS.PROPOSAL}/${data.publicKey}`)
    },
    [isEdit, navigate],
  )

  const rowParams = useMemo(() => {
    return { onRowClick }
  }, [onRowClick])

  return (
    <Table
      data={data}
      columns={columns}
      className={styles.table}
      rowParams={rowParams}
      classNameTableWrapper={styles.tableWrapper}
      loading={isLoading}
      loaderClassName={styles.loader}
      emptyMessage={!data.length ? 'No proposals found' : ''}
    />
  )
}

export default ProposalsTable
