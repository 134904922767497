import { WalletContextState } from '@solana/wallet-adapter-react'
import { Connection } from '@solana/web3.js'
import { web3 } from 'banx-claim-sdk/lib/utils'
import {
  GetPriorityFee,
  Wallet,
  WalletAndConnection,
  extractAccountKeysFromInstructions,
} from 'solana-transactions-executor'

import { helius } from '@banx/api/common'
import { getPriorityFeeLevel } from '@banx/store/usePriorityFees'

type CreateExecutorWalletAndConnection = (params: {
  wallet: WalletContextState
  connection: web3.Connection
}) => WalletAndConnection
export const createExecutorWalletAndConnection: CreateExecutorWalletAndConnection = ({
  wallet,
  connection,
}) => {
  return { wallet: createExecutorWallet(wallet), connection }
}

const createExecutorWallet = (wallet: WalletContextState): Wallet => {
  const { publicKey, signTransaction, signAllTransactions } = wallet

  if (!publicKey) {
    throw new Error('Public key is not available')
  }

  if (!signTransaction) {
    throw new Error('Sign transaction method is not available')
  }

  return {
    publicKey,
    signTransaction,
    signAllTransactions,
  }
}

export const executorGetPriorityFee: GetPriorityFee = ({ txnParams, connection }) => {
  const priorityLevel = getPriorityFeeLevel()

  const { instructions } = txnParams
  const accountKeys = extractAccountKeysFromInstructions(instructions).map((key) => key.toBase58())

  return helius.getHeliusPriorityFeeEstimate({ accountKeys, connection, priorityLevel })
}
