import moment from 'moment'

export const calculateVotePercentage = (voters: number, totalVoters: number) => {
  return (voters / totalVoters) * 100
}

export const formatDateRange = (start: number, duration: number) => {
  const startDate = moment(start * 1000).format('D MMM')
  const endDate = moment((start + duration) * 1000).format('D MMM')
  return `${startDate} — ${endDate}`
}
