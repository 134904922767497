import { FC, SVGProps } from 'react'

export const Wallet: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_18955_123647)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6425 3.82202H2.52625C2.24659 3.79793 1.9871 3.66654 1.80214 3.4554C1.66488 3.3488 1.5722 3.19494 1.54215 3.02377C1.52437 2.39767 2.2429 1.96503 2.89288 1.96503H11.2501C11.6191 1.96637 11.9725 2.11356 12.2334 2.3745C12.4942 2.63544 12.6413 2.98895 12.6425 3.3579V3.82202ZM13.1066 4.75078H2.42875C2.18247 4.75064 1.94632 4.65275 1.77217 4.4786C1.59803 4.30446 1.50013 4.0683 1.5 3.82202V12.6434C1.50027 13.0127 1.64712 13.3668 1.90829 13.6278C2.16946 13.8889 2.52359 14.0356 2.89288 14.0358H13.1066C13.4759 14.0356 13.83 13.8889 14.0912 13.6278C14.3524 13.3668 14.4992 13.0127 14.4995 12.6434V11.2505H12.1784C11.6858 11.2505 11.2134 11.0548 10.8651 10.7065C10.5168 10.3582 10.3211 9.88585 10.3211 9.39327C10.3211 8.9007 10.5168 8.4283 10.8651 8.08C11.2134 7.73169 11.6858 7.53602 12.1784 7.53602H14.4995V6.14365C14.4994 5.77428 14.3526 5.42008 14.0914 5.15889C13.8302 4.8977 13.476 4.75091 13.1066 4.75078ZM11.2506 9.39353C11.2509 9.63972 11.3489 9.87574 11.523 10.0498C11.6971 10.2238 11.9332 10.3216 12.1794 10.3218H14.5V8.46528H12.1794C11.9331 8.46542 11.697 8.56331 11.5228 8.73745C11.3487 8.9116 11.2508 9.14775 11.2506 9.39403V9.39353Z"
        fill="#656565"
      />
    </g>
    <defs>
      <clipPath id="clip0_18955_123647">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
