import { memo } from 'react'

import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { Loader } from '../Loader'
import { TableProps } from './types'
import { TableView } from './views'

import styles from './Table.module.less'

const Table = <DataType extends object>({
  data,
  columns,
  rowParams,
  loading,
  emptyMessage,
  className,
  classNameTableWrapper,
  loadMore,
  loaderSize,
  loaderClassName,
}: TableProps<DataType>) => {
  const hasData = !isEmpty(data)

  return (
    <>
      <div className={classNames(styles.tableWrapper, classNameTableWrapper)}>
        {loading && <Loader className={loaderClassName} size={loaderSize} />}

        {emptyMessage && !loading && <div className={styles.emptyList}>{emptyMessage}</div>}

        {hasData && (
          <TableView
            data={data}
            columns={columns}
            rowParams={rowParams}
            className={className}
            loadMore={loadMore}
          />
        )}
      </div>
    </>
  )
}

export default memo(Table) as typeof Table
