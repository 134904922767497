import { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import { useAllGovernanceAccounts } from '../RootPage/components/Proposals/hooks'
import { useBanxStakeInfo } from '../RootPage/hooks'

export const useProposalPage = () => {
  const { id: proposalId } = useParams()

  const { data, isLoading: isLoadingProposals } = useAllGovernanceAccounts()
  const { banxStakeInfo, isLoading: isLoadingStakeInfo } = useBanxStakeInfo()

  const currentProposal = useMemo(() => {
    if (!data) return null

    return data.find(({ publicKey }) => publicKey === proposalId)
  }, [data, proposalId])

  const banxAdventuresWithSubscription = useMemo(() => {
    return banxStakeInfo?.banxAdventures.find(
      ({ adventure }) => adventure.week.toNumber() === currentProposal?.week,
    )
  }, [banxStakeInfo, currentProposal])

  const isLoading = isLoadingProposals || isLoadingStakeInfo

  return {
    currentProposal,
    banxAdventuresWithSubscription,
    isLoading,
  }
}
