import axios from 'axios'

import { BACKEND_BASE_URL } from '@banx/constants'

import { parseResponseSafe } from './helpers'
import { BanxStakingInfo, BanxStakingInfoSchema } from './types'

type FetchBanxStakeInfo = (props: { userPubkey?: string }) => Promise<BanxStakingInfo | undefined>
export const fetchBanxStakeInfo: FetchBanxStakeInfo = async ({ userPubkey }) => {
  const queryParams = new URLSearchParams({
    walletPubkey: userPubkey ?? '',
  })

  const { data } = await axios.get<{ data: BanxStakingInfo }>(
    `${BACKEND_BASE_URL}/staking/v2/info?${queryParams.toString()}`,
  )

  return await parseResponseSafe<BanxStakingInfo>(data.data, BanxStakingInfoSchema)
}
