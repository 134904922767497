import { web3 } from 'banx-claim-sdk/lib/utils'
import { CreateTxnData, WalletAndConnection } from 'solana-transactions-executor'

import { saveVariant } from '@banx/governance-sdk/functions/variant'
import { sendTxnPlaceHolder } from '@banx/utils'

export type CreateProposalVariantTxnDataParams = {
  name: string
  proposalPubkey: string
}

type CreateProposalVariantTxnData = (
  params: CreateProposalVariantTxnDataParams,
  walletAndConnection: WalletAndConnection,
) => Promise<CreateTxnData<CreateProposalVariantTxnDataParams>>

export const createProposalVariantTxnData: CreateProposalVariantTxnData = async (
  params,
  walletAndConnection,
) => {
  const { name, proposalPubkey } = params

  const { instructions, signers } = await saveVariant({
    args: {
      name,
    },
    accounts: {
      userPubkey: walletAndConnection.wallet.publicKey,
      proposalPubkey: new web3.PublicKey(proposalPubkey),
    },
    connection: walletAndConnection.connection,
    sendTxn: sendTxnPlaceHolder,
  })

  return {
    params,
    accounts: [],
    instructions,
    signers,
    lookupTables: [],
  }
}
