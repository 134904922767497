import { decodeProposalName } from '../helpers'
import { Proposal, ProposalState, ProposalVariant, ProposalVote } from '../types'

export const convertChainProposal = (accountWrapper: any): Proposal => {
  const rawAccount = accountWrapper.account
  return {
    publicKey: accountWrapper.publicKey.toString(),
    state: Object.keys(rawAccount.state)[0] as ProposalState,
    name: decodeProposalName(rawAccount.name),
    week: rawAccount.week.toNumber(),
    duration: rawAccount.duration.toNumber(),
    pointsQuorum: rawAccount.pointsQuorum.toString(),
    participants: rawAccount.participants.toNumber(),
    pointsTotal: rawAccount.pointsTotal.toString(),
    creator: rawAccount.creator.toString(),
    lastTransactedAt: rawAccount.lastTransactedAt.toNumber(),
    initializedAt: rawAccount.initializedAt.toNumber(),
  }
}

export const convertChainVariant = (accountWrapper: any): ProposalVariant => {
  const rawAccount = accountWrapper.account
  return {
    publicKey: accountWrapper.publicKey.toString(),
    proposal: rawAccount.proposal.toString(),
    name: decodeProposalName(rawAccount.name),
    state: Object.keys(rawAccount.state)[0] as ProposalState,
    participants: rawAccount.participants.toNumber(),
    pointsTotal: rawAccount.pointsTotal.toString(),
    lastTransactedAt: rawAccount.participants.toNumber(),
  }
}

export const convertChainVote = (accountWrapper: any): ProposalVote => {
  const rawAccount = accountWrapper.account
  return {
    state: Object.keys(rawAccount.state)[0] as ProposalState,
    proposalVariant: rawAccount.proposalVariant.toString(),
    adventureSubscription: rawAccount.adventureSubscription.toString(),
    user: rawAccount.user.toString(),
    points: rawAccount.points.toString(),
    lastTransactedAt: rawAccount.lastTransactedAt.toNumber(),
    publicKey: accountWrapper.publicKey.toString(),
  }
}
