import { useMemo } from 'react'

import { Tabs, useTabs } from '@banx/components/Tabs'

import { isProposalEnded } from '../../helpers'
import ProposalsTable from './ProposalsTable'
import { useAllGovernanceAccounts } from './hooks'

import styles from './Proposals.module.less'

const Proposals = () => {
  const { data, isLoading } = useAllGovernanceAccounts()

  const { value: currentTabValue, ...tabsProps } = useTabs({
    tabs: BONDS_TABS,
    defaultValue: TabName.Current,
  })

  const { activeProposals, completedProposals } = useMemo(() => {
    const activeProposals = data.filter((proposal) => !isProposalEnded(proposal))
    const completedProposals = data.filter((proposal) => isProposalEnded(proposal))

    return { activeProposals, completedProposals }
  }, [data])

  return (
    <div className={styles.proposalsBlock}>
      <div className={styles.proposalsContentHeader}>
        <h2 className={styles.proposalsContentTitle}>Proposals</h2>
      </div>

      <Tabs value={currentTabValue} {...tabsProps} />

      {currentTabValue === TabName.Current && (
        <ProposalsTable data={activeProposals} isLoading={isLoading} />
      )}
      {currentTabValue === TabName.Completed && (
        <ProposalsTable data={completedProposals} isLoading={isLoading} />
      )}
    </div>
  )
}

export default Proposals

enum TabName {
  Current = 'current',
  Completed = 'completed',
}

const BONDS_TABS = [
  {
    label: 'Current',
    value: TabName.Current,
  },
  {
    label: 'Completed',
    value: TabName.Completed,
  },
]
