import { FC } from 'react'

import moment from 'moment'

import { Grow, Hourglass, Wallet } from '@banx/icons'
import { MergedProposal } from '@banx/pages/RootPage/components/Proposals/hooks'
import VoteStatusBadge from '@banx/pages/RootPage/components/VoteStatusBadge'
import { shortenAddress } from '@banx/utils'

import styles from '../ProposalPage.module.less'

export const StatusInfoBlock: FC<{ proposal: MergedProposal }> = ({ proposal }) => {
  const { initializedAt: startedAt, duration, creator } = proposal

  const endedAt = startedAt + duration

  const formattedStartedAt = moment.unix(startedAt).format('DD MMM, hh:mm A')
  const formattedEndedAt = moment.unix(endedAt).format('DD MMM, hh:mm A')

  return (
    <div className={styles.contentBlock}>
      <div className={styles.statusInfoList}>
        <div className={styles.statusInfoItem}>
          <div className={styles.statusInfoItemLabel}>
            <Grow />
            <span>Status:</span>
          </div>
          <VoteStatusBadge proposal={proposal} />
        </div>
        <div className={styles.statusInfoItem}>
          <div className={styles.statusInfoItemLabel}>
            <Wallet />
            <span>Created by:</span>
          </div>
          <span className={styles.statusInfoItemValue}>{shortenAddress(creator)}</span>
        </div>
        <div className={styles.statusInfoItem}>
          <div className={styles.statusInfoItemLabel}>
            <Hourglass />
            <span>Start:</span>
          </div>
          <span className={styles.statusInfoItemValue}>{formattedStartedAt}</span>
        </div>
        <div className={styles.statusInfoItem}>
          <div className={styles.statusInfoItemLabel}>
            <Hourglass className={styles.statusInfoItemLabelIconRotate} />
            <span>End:</span>
          </div>
          <span className={styles.statusInfoItemValue}>{formattedEndedAt}</span>
        </div>
      </div>
    </div>
  )
}
