import { FC, SVGProps } from 'react'

export const Grow: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_18955_130240)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 2.00018C13.7015 2.00018 14 2.29866 14 2.66685V13.3335C14 13.7017 13.7015 14.0002 13.3333 14.0002C12.9651 14.0002 12.6667 13.7017 12.6667 13.3335V2.66685C12.6667 2.29866 12.9651 2.00018 13.3333 2.00018ZM10.6667 4.66685C11.0349 4.66685 11.3333 4.96533 11.3333 5.33352V13.3335C11.3333 13.7017 11.0349 14.0002 10.6667 14.0002C10.2985 14.0002 10 13.7017 10 13.3335V5.33352C10 4.96533 10.2985 4.66685 10.6667 4.66685ZM8 7.33352C8.36819 7.33352 8.66667 7.63199 8.66667 8.00018V13.3335C8.66667 13.7017 8.36819 14.0002 8 14.0002C7.63181 14.0002 7.33333 13.7017 7.33333 13.3335V8.00018C7.33333 7.63199 7.63181 7.33352 8 7.33352ZM5.33333 10.0002C5.70152 10.0002 6 10.2987 6 10.6669V13.3335C6 13.7017 5.70152 14.0002 5.33333 14.0002C4.96514 14.0002 4.66667 13.7017 4.66667 13.3335V10.6669C4.66667 10.2987 4.96514 10.0002 5.33333 10.0002ZM2.66667 12.0002C3.03486 12.0002 3.33333 12.2987 3.33333 12.6669V13.3335C3.33333 13.7017 3.03486 14.0002 2.66667 14.0002C2.29848 14.0002 2 13.7017 2 13.3335V12.6669C2 12.2987 2.29848 12.0002 2.66667 12.0002Z"
        fill="#656565"
      />
    </g>
    <defs>
      <clipPath id="clip0_18955_130240">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
