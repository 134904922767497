import { web3 } from '@project-serum/anchor'

export const ENCODER = new TextEncoder()

export async function getProposalPubkey(
  program: { programId: web3.PublicKey },
  args: {
    name: string
    week: number
  },
) {
  const [proposal] = await web3.PublicKey.findProgramAddress(
    [encodeProposalName(args), ENCODER.encode(args.week.toString())],
    program.programId,
  )
  return proposal
}

export function bufferToArray(buffer: Buffer): number[] {
  const nums: number[] = []
  for (let i = 0; i < buffer.length; i++) {
    nums.push(buffer[i])
  }
  return nums
}

export function encodeProposalName(args: { name: string }): Buffer {
  if (!args.name) throw new Error('Name is required')
  if (args.name.includes('\0')) throw new Error('Name contains forbidden character')
  // Check that name contains a forbidden character

  const size = 32
  const encodedBytes = Buffer.from(args.name, 'utf-8')
  if (encodedBytes.length > size) throw new Error('Name too long')

  // Если меньше, добавим нулевые байты для заполнения до 128 байт
  const paddedBuffer = Buffer.alloc(size)
  encodedBytes.copy(paddedBuffer)
  return paddedBuffer
}

export function decodeProposalName(value: string): string {
  return (
    Buffer.from(value)
      .toString('utf-8')
      // Since the byte array may contain padding (null bytes, represented as 0), you can use replace(/\0/g, '') to remove these null bytes from the resulting string.
      .replace(/\0/g, '')
  )
}

export async function getProposalVariantPubkey(
  program: { programId: web3.PublicKey },
  args: {
    name: string
  },
  accounts: {
    proposalPubkey: web3.PublicKey
  },
) {
  const [variant] = await web3.PublicKey.findProgramAddress(
    [encodeProposalName(args), accounts.proposalPubkey.toBuffer()],
    program.programId,
  )
  return variant
}

export async function getVotePubkey(
  program: { programId: web3.PublicKey },
  accounts: {
    proposalPubkey: web3.PublicKey
    userPubkey: web3.PublicKey
  },
) {
  const [vote] = await web3.PublicKey.findProgramAddress(
    [accounts.userPubkey.toBuffer(), accounts.proposalPubkey.toBuffer()],
    program.programId,
  )
  return vote
}

export const anchorRawBNsAndPubkeysToNumsAndStrings = (rawAccount: {
  publicKey: web3.PublicKey
  account: object
}) => {
  const copyRawAccount = { ...rawAccount }
  const newAccount = parseRawAccount(rawAccount.account)
  return { ...newAccount, publicKey: copyRawAccount.publicKey.toString() }
}

const parseRawAccount = (rawAccount: any) => {
  const copyRawAccount = { ...rawAccount }
  for (const key in copyRawAccount) {
    if (key === 'name') {
      copyRawAccount[key] = decodeProposalName(copyRawAccount[key])
      continue
    }

    if (copyRawAccount[key] === null || copyRawAccount[key] === undefined) continue

    if (copyRawAccount[key].toNumber) {
      try {
        copyRawAccount[key] = copyRawAccount[key].toNumber()
      } catch (err) {
        copyRawAccount[key] = 0
      }
    }

    if (copyRawAccount[key].toBase58) {
      copyRawAccount[key] = copyRawAccount[key].toBase58()
    }

    if (typeof copyRawAccount[key] === 'object' && Object.keys(copyRawAccount[key]).length === 1) {
      copyRawAccount[key] = Object.keys(copyRawAccount[key])[0]
    } else if (typeof copyRawAccount[key] === 'object') {
      copyRawAccount[key] = parseRawAccount(copyRawAccount[key])
    }
  }
  return copyRawAccount
}
