import { ColumnType } from '@banx/components/Table'
import { HeaderCell } from '@banx/components/TableComponents'

import { VoteProgressBar } from '@banx/pages/ProposalPage/components/VotesInfoBlock'
import { isProposalEnded } from '@banx/pages/RootPage/helpers'

import VoteStatusBadge from '../../VoteStatusBadge'
import { MergedProposal } from '../hooks'
import { DurationCell, NameCell, VotedCell } from './cells'
import AdminActionCell from './cells/AdminActionCell'

import styles from './ProposalsTable.module.less'

interface GetTableColumnsProps {
  walletPubkey: string
  isEdit?: boolean
}

export const getTableColumns = ({ walletPubkey, isEdit }: GetTableColumnsProps) => {
  const nameColumn: ColumnType<MergedProposal> = {
    key: 'name',
    title: <HeaderCell label="Name" align="left" />,
    render: (proposal) => <NameCell name={proposal.name} />,
  }

  const statusColumn: ColumnType<MergedProposal> = {
    key: 'status',
    title: <HeaderCell label="Status" />,
    render: (proposal) => <VoteStatusBadge proposal={proposal} />,
  }

  const weekColumn: ColumnType<MergedProposal> = {
    key: 'week',
    title: <HeaderCell label="Week" />,
    render: (proposal) => <DurationCell proposal={proposal} />,
  }

  const votedColumn: ColumnType<MergedProposal> = {
    key: 'voted',
    title: <HeaderCell label="Voted" />,
    render: (proposal) => <VotedCell proposal={proposal} userWalletPubkey={walletPubkey} />,
  }

  const resultsColumn: ColumnType<MergedProposal> = {
    key: 'results',
    title: <HeaderCell label="Results" />,
    render: (proposal) =>
      isProposalEnded(proposal) ? (
        <VoteProgressBar
          variants={proposal.variants}
          pointsTotal={parseFloat(proposal.pointsTotal)}
          className={styles.resultsCell}
        />
      ) : (
        '-'
      ),
  }

  const actionColumn: ColumnType<MergedProposal> = {
    key: 'action',
    render: (data) => <AdminActionCell data={data} />,
  }

  const userColumns: ColumnType<MergedProposal>[] = [
    nameColumn,
    votedColumn,
    statusColumn,
    resultsColumn,
    weekColumn,
  ]

  const adminColumns: ColumnType<MergedProposal>[] = [
    nameColumn,
    statusColumn,
    weekColumn,
    actionColumn,
  ]

  return isEdit ? adminColumns : userColumns
}
