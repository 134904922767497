import { web3 } from '@project-serum/anchor'
import { returnAnchorProgram } from 'fbonds-core/lib/fbond-protocol/helpers'

import { getVotePubkey } from '../helpers'

interface VoteAccountsToCreate {
  proposalPubkey: web3.PublicKey
  proposalVariantPubkey: web3.PublicKey
  adventurePubkey: web3.PublicKey //? Adventure pubkey of proposal week
  adventureSubscriptionPubkey: web3.PublicKey //? Adventure pubkey of proposal week
  userPubkey: web3.PublicKey
}

interface VoteAccounts extends VoteAccountsToCreate {
  previousProposalVariantPubkey: web3.PublicKey
}

type CreateVote = (params: {
  connection: web3.Connection
  accounts: VoteAccountsToCreate
  sendTxn: (transaction: web3.Transaction, signers: web3.Signer[]) => Promise<void>
}) => Promise<{
  instructions: web3.TransactionInstruction[]
  signers: web3.Signer[]
}>

type Vote = (params: {
  connection: web3.Connection
  accounts: VoteAccounts
  sendTxn: (transaction: web3.Transaction, signers: web3.Signer[]) => Promise<void>
}) => Promise<{
  instructions: web3.TransactionInstruction[]
  signers: web3.Signer[]
}>

// eslint-disable-next-line require-await
export const createVote: CreateVote = async ({ connection, accounts, sendTxn }) => {
  const previousProposalVariantPubkey = accounts.proposalVariantPubkey
  return vote({
    connection,
    accounts: { ...accounts, previousProposalVariantPubkey },
    sendTxn,
  })
}

// eslint-disable-next-line require-await
export const updateVote: Vote = async ({ connection, accounts, sendTxn }) => {
  if (accounts.previousProposalVariantPubkey === accounts.proposalVariantPubkey)
    throw new Error('No change in variant')
  return vote({ connection, accounts, sendTxn })
}

const vote: Vote = async ({ connection, accounts, sendTxn }) => {
  const program = returnAnchorProgram(connection)

  const instructions: web3.TransactionInstruction[] = []
  const previousProposalVariant =
    accounts.previousProposalVariantPubkey || accounts.proposalVariantPubkey

  instructions.push(
    await program.methods
      .vote()
      .accountsStrict({
        adventure: accounts.adventurePubkey,
        adventureSubscription: accounts.adventureSubscriptionPubkey,
        proposal: accounts.proposalPubkey,
        proposalVariant: accounts.proposalVariantPubkey,
        user: accounts.userPubkey,

        previousProposalVariant,
        vote: await getVotePubkey(program, accounts),

        systemProgram: web3.SystemProgram.programId,
        rent: web3.SYSVAR_RENT_PUBKEY,
      })
      .instruction(),
  )

  const transaction = new web3.Transaction()
  for (const instruction of instructions) transaction.add(instruction)

  const signers: web3.Signer[] = []
  await sendTxn(transaction, signers)

  return {
    instructions,
    signers,
  }
}
