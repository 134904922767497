import { FC, SVGProps } from 'react'

export const Globe: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M7.12481 8.99981C7.12481 8.37657 7.15348 7.77517 7.20667 7.20667C7.77517 7.15348 8.37657 7.12481 8.99981 7.12481C9.62305 7.12481 10.2245 7.15348 10.793 7.20667C10.8461 7.77517 10.8748 8.37657 10.8748 8.99981C10.8748 9.62305 10.8461 10.2245 10.793 10.793C10.2245 10.8461 9.62305 10.8748 8.99981 10.8748C8.37657 10.8748 7.77517 10.8461 7.20667 10.793C7.15348 10.2245 7.12481 9.62305 7.12481 8.99981Z"
      fill="#D3D3D3"
    />
    <path
      d="M6.37481 8.99981C6.37481 8.41286 6.39935 7.84165 6.44581 7.295C5.64982 7.40614 4.93761 7.56718 4.34543 7.76458C3.75651 7.96088 3.30698 8.18637 3.01238 8.41854C2.71355 8.65404 2.62481 8.8533 2.62481 8.99981C2.62481 9.14632 2.71355 9.34558 3.01238 9.58108C3.30698 9.81326 3.75651 10.0387 4.34543 10.235C4.93761 10.4324 5.64982 10.5935 6.44581 10.7046C6.39935 10.158 6.37481 9.58676 6.37481 8.99981Z"
      fill="#D3D3D3"
    />
    <path
      d="M4.10826 7.05307C4.79539 6.82402 5.61746 6.64417 6.52739 6.52739C6.64417 5.61746 6.82402 4.79539 7.05307 4.10826C7.26393 3.47566 7.52328 2.93668 7.82948 2.54815C7.89371 2.46665 7.96197 2.38944 8.0342 2.31836C5.07907 2.74166 2.74166 5.07907 2.31836 8.0342C2.38944 7.96197 2.46665 7.89371 2.54815 7.82948C2.93668 7.52328 3.47566 7.26393 4.10826 7.05307Z"
      fill="#D3D3D3"
    />
    <path
      d="M9.96543 2.31836C10.0377 2.38944 10.1059 2.46665 10.1701 2.54815C10.4763 2.93668 10.7357 3.47566 10.9466 4.10826C11.1756 4.79539 11.3555 5.61746 11.4722 6.52739C12.3822 6.64417 13.2042 6.82402 13.8914 7.05307C14.524 7.26393 15.0629 7.52328 15.4515 7.82948C15.533 7.89371 15.6102 7.96197 15.6813 8.0342C15.258 5.07906 12.9206 2.74166 9.96543 2.31836Z"
      fill="#D3D3D3"
    />
    <path
      d="M15.6813 9.96543C15.6102 10.0377 15.533 10.1059 15.4515 10.1701C15.0629 10.4763 14.524 10.7357 13.8914 10.9466C13.2042 11.1756 12.3822 11.3555 11.4722 11.4722C11.3555 12.3822 11.1756 13.2042 10.9466 13.8914C10.7357 14.524 10.4763 15.0629 10.1701 15.4515C10.1059 15.533 10.0377 15.6102 9.96543 15.6813C12.9206 15.258 15.258 12.9206 15.6813 9.96543Z"
      fill="#D3D3D3"
    />
    <path
      d="M8.0342 15.6813C7.96197 15.6102 7.89371 15.533 7.82948 15.4515C7.52328 15.0629 7.26393 14.524 7.05307 13.8914C6.82402 13.2042 6.64417 12.3822 6.52739 11.4722C5.61746 11.3555 4.79539 11.1756 4.10826 10.9466C3.47566 10.7357 2.93668 10.4763 2.54815 10.1701C2.46665 10.1059 2.38944 10.0377 2.31836 9.96543C2.74166 12.9206 5.07906 15.258 8.0342 15.6813Z"
      fill="#D3D3D3"
    />
    <path
      d="M8.99981 6.37481C8.41286 6.37481 7.84164 6.39935 7.295 6.44581C7.40614 5.64982 7.56718 4.93761 7.76458 4.34543C7.96088 3.75651 8.18637 3.30698 8.41854 3.01238C8.65404 2.71355 8.8533 2.62481 8.99981 2.62481C9.14632 2.62481 9.34558 2.71355 9.58108 3.01238C9.81326 3.30698 10.0387 3.75651 10.235 4.34543C10.4324 4.93761 10.5935 5.64982 10.7046 6.44581C10.158 6.39935 9.58676 6.37481 8.99981 6.37481Z"
      fill="#D3D3D3"
    />
    <path
      d="M11.6248 8.99981C11.6248 8.41286 11.6003 7.84165 11.5538 7.295C12.3498 7.40614 13.062 7.56718 13.6542 7.76458C14.2431 7.96088 14.6926 8.18637 14.9872 8.41854C15.2861 8.65404 15.3748 8.8533 15.3748 8.99981C15.3748 9.14632 15.2861 9.34558 14.9872 9.58108C14.6926 9.81326 14.2431 10.0387 13.6542 10.235C13.062 10.4324 12.3498 10.5935 11.5538 10.7046C11.6003 10.158 11.6248 9.58676 11.6248 8.99981Z"
      fill="#D3D3D3"
    />
    <path
      d="M8.99981 11.6248C9.58676 11.6248 10.158 11.6003 10.7046 11.5538C10.5935 12.3498 10.4324 13.062 10.235 13.6542C10.0387 14.2431 9.81326 14.6926 9.58108 14.9872C9.34558 15.2861 9.14632 15.3748 8.99981 15.3748C8.8533 15.3748 8.65404 15.2861 8.41854 14.9872C8.18637 14.6926 7.96088 14.2431 7.76458 13.6542C7.56718 13.062 7.40614 12.3498 7.295 11.5538C7.84165 11.6003 8.41286 11.6248 8.99981 11.6248Z"
      fill="#D3D3D3"
    />
  </svg>
)
