import { web3 } from '@project-serum/anchor'
import { returnAnchorProgram } from 'fbonds-core/lib/fbond-protocol/helpers'

import { Proposal, ProposalVariant, ProposalVote } from '@banx/governance-sdk/types'

import { convertChainProposal, convertChainVariant, convertChainVote } from './converters'

export const getAllGovernanceAccounts = async (
  connection: web3.Connection,
): Promise<{
  proposals: Proposal[]
  votes: ProposalVote[]
  variants: ProposalVariant[]
}> => {
  const program = returnAnchorProgram(connection)

  const proposals = (await program.account.proposal.all()).map(convertChainProposal)

  const variants = (await program.account.proposalVariant.all()).map(convertChainVariant)

  const votes = (await program.account.proposalVote.all()).map(convertChainVote)

  console.log({ proposals, variants, votes })

  return {
    proposals,
    variants,
    votes,
  }
}
