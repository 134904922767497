import { FC } from 'react'

import { CircleCheck } from '@banx/icons'

import { MergedProposal } from '../../hooks'
import { formatDateRange } from './helpers'

import styles from '../ProposalsTable.module.less'

export * from './AdminActionCell'

export const NameCell: FC<{ name: string }> = ({ name }) => {
  return <div className={styles.nameCell}>{name}</div>
}

interface VotedCellProps {
  proposal: MergedProposal
  userWalletPubkey: string
}

export const VotedCell: FC<VotedCellProps> = ({ proposal, userWalletPubkey }) => {
  const userVoted = proposal.variants.some((variant) =>
    variant.votes.some((vote) => vote.user === userWalletPubkey),
  )

  return <div className={styles.votedCell}>{userVoted ? <CircleCheck /> : '-'}</div>
}

export const DurationCell: FC<{ proposal: MergedProposal }> = ({ proposal }) => {
  const { initializedAt, duration, week } = proposal

  const dateRange = formatDateRange(initializedAt, duration)

  return (
    <div className={styles.durationCell}>
      <span>{week}</span>
      <span>{dateRange}</span>
    </div>
  )
}
